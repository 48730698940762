<!--  -->
<template>
  <div>
    <van-sticky>
      <!-- 盘点类型 -->
      <div class="point_top">
        <i class="el-icon-arrow-left" @click="back()"></i>
        <!-- <div class="point_kind">{{ pointTitle.inventoryTypeName }}</div> -->
        <div class="point_kind">入库差异</div>
        <div class="point_point"></div>
      </div>
    </van-sticky>
    <!-- 时间筛选 -->
    <div style="width:92%;height:30vw;margin-left:2%;padding:0 2vw;background: #fff;border-radius: 2vw;margin-top: 2vw;" >
          <div class="every_option">
              <div style="display:flex;">
                <div style="font-size:3.4vw;margin-left:6vw;">开始时间</div>
              </div>
              <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(1)">
                <div style="font-size:3.4vw;margin-left:7vw;" >{{query.startTime?query.startTime:'点击选择'}}</div>
              </div>
          </div>
          <div  class="every_option">
            <div style="display:flex;">
              <div style="font-size:3.4vw;margin-left:6vw;">结束时间</div>
            </div>
            <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(2)">
              <div style="font-size:3.4vw;margin-left:7vw;" >{{query.endTime?query.endTime:'点击选择'}}</div>
            </div>
          </div>
        </div>
        <!-- 日期选择 -->
        <van-popup v-model="choose_popup" position="bottom" >
            <van-datetime-picker v-model="currentDate" type="date" title="选择年月日"  :max-date="maxDate" @confirm="chooseDateBtn" @cancel="cancelBtn"/>
        </van-popup>
        <!-- 输入货号查询 输入供应商代码查询 -->
        <div style="width:92%;height:30vw;margin-left:2%;background: #fff;border-radius: 2vw;padding:0 2vw;" >
          <div class="every_option1">
              <div style="display:flex;">
                <div style="font-size:3.4vw;margin-left:6vw;">商品货号</div>
              </div>
              <el-input size="small" style="width:65vw;" placeholder="请输入货号" v-model="goodsVal" ></el-input>
          </div>
          <div  class="every_option1">
            <div >
            <div style="display: flex; align-items: center; ">
              <div style="font-size:3.4vw;margin-left:6vw;">供应商</div>
              <!-- <el-input v-model="valus" @blur="goods"></el-input> -->
              <el-autocomplete
                size="small"
                popper-class="my-autocomplete"
                v-model="stateList"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect"
                style="width: 65vw;margin-left: 10.5vw;"
              >
                <i
                  class="el-icon-edit el-input__icon"
                  slot="suffix"
                  @click="handleIconClick"
                >
                </i>
                <template slot-scope="{ item }">
                  <div class="name">{{ item.供应商名称 }}</div>
                </template>
              </el-autocomplete>
              <!-- <el-select v-model="state" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
            </div>
          </div>
            <!-- <div style="display:flex;">
              <div style="font-size:3.4vw;margin-left:6vw;">供应商代码</div>
            </div>
            <el-input size="small" style="width:65vw" placeholder="请输入供应商代码" v-model="billVal"></el-input> -->
          </div>
        </div>
        <div style="display:flex;justify-content:center;align-items:center"><el-button @click="queryDiff()">查询</el-button></div>
        <el-divider></el-divider>
        <!-- <div>
      <div
        style="
          width: 98%;
          height: 10vw;
          background: #4bc4fa;
          margin-left: 1%;
          margin-top: 2vw;
          border-radius: 2vw 2vw 0 0;
          display: flex;
          justify-content: space-around;
          align-items: center;
          color: #fff;
        "
      >
        <div>货号</div>
        <div>名称</div>
        <div>雨人数量</div>
        <div>实扫数量</div>
        <div>差异数量</div>
      </div>
      <div
        v-for="(item, index) in diffList"
        :key="index"
        style="
        display: flex;
          width: 98%;
          height: 10vw;
          margin-left: 1%;
          color: #000;
          background: rgba(190, 190, 190, 0.2);
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div style="width: 15vw; margin-left: 4vw">
          <el-tooltip placement="top">
            <div slot="content">{{ item.itemNo }}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 10vw">
              {{ item.itemNo }}
            </div>
          </el-tooltip>
        </div>
        <div style="width: 15vw; margin-right: 5vw">
          <el-tooltip placement="top">
            <div slot="content">{{ item.itemName }}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 11vw">
              {{ item.itemName }}
            </div>
          </el-tooltip>
        </div>
        <div style="width: 15vw; margin-right: 11vw">
          <el-tooltip placement="top">
            <div slot="content">{{ item.bookQuantity }}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 14.5vw">
              {{ item.bookQuantity }}
            </div>
          </el-tooltip>
        </div>
        <div style="width: 15vw; margin-right: 2vw">
          <el-tooltip placement="top">
            <div slot="content">{{ item.actualQuantity }}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 13vw">
              {{ item.actualQuantity }}
            </div>
          </el-tooltip>
        </div>
        <div style="width: 15vw; margin-right: 2vw">
          <el-tooltip placement="top">
            <div slot="content">{{a}}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 13vw">
            {{a}}
            </div>
          </el-tooltip>
        </div>
      </div>

    </div> -->
    <div style="font-size: 2vw;margin-left: 3%;margin-top: 1vw;font-size: 3vw;"  v-if="diffList.length>0" >
      <table border="1" cellspacing="0" width="96%">
        <tr>
          <td width="14%" align="center">货号</td>
        
          <td width="14.2%" align="center">商品名称</td>
          <!-- <td width="14.2%" align="center">生产单位</td> -->
          <td width="16%" align="center">雨人数量</td>
          <td width="16%" align="center">实扫数量</td>
          <td width="16%" align="center">差异数量</td>
        </tr>
        <tr v-for="(item, index) in diffList" :key="index">
          <td width="10%" align="center">
            <el-tooltip placement="top">
                  <div slot="content">{{ item.itemNo }}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width: 9vw;"  >{{ item.itemNo }}</div>
            </el-tooltip>
          
          </td>
          <td width="10%" align="center" >
            <el-tooltip placement="top">
                  <div slot="content">{{ item.itemName }}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width:12.5vw;"  >{{ item.itemName }}</div>
            </el-tooltip>
            
          </td>
          <td width="14.2%" align="center">
            <el-tooltip placement="top">
                  <div slot="content">{{ item.bookQuantity }}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width: 13vw;"  >{{ item.bookQuantity }}</div>
            </el-tooltip>
            
           </td>
          <td width="14.2%" align="center">
            <el-tooltip placement="top">
                  <div slot="content">{{ item.actualQuantity }}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width: 13vw;"  >{{ item.actualQuantity }}</div>
            </el-tooltip>
          </td>
          <td width="16%%" align="center">{{item.bookQuantity-item.actualQuantity}}</td>
        
        </tr>
        
        <!-- <tr>
          <td width="10%" align="center">总计</td>
          
          <td width="14.2%" align="center"></td>
          <td width="14.2%" align="center">{{yuren}}</td>
          <td width="14.2%" align="center">{{shiji}}</td>
          <td width="14.2%" align="center"></td>
    
        </tr> -->
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      query:{
       
       startTime:'',
       endTime:'',
      
     },
     popup_type:0,//弹出层 1日期筛选 2子表
     choose_type:0,//弹窗状态 1开始时间 2结束时间
     choose_popup:false,
     maxDate: 0,//时间不超过今天
     currentDate: 0,//用户选中的时间
     billVal:'',
     goodsVal:"",
     allSub:{},
     diffList:[],
     yuren:"",
     shiji:"",
     stateList:"",
     inoubill:[],
     valus:""
    
    }
  },
 mounted(){
  this.goods()
  let new_time = new Date();
      this.maxDate = new_time;
      this.currentDate = new_time;
 },
  methods: {
    goods() {
      this.$axios
        .get(this.$api.inoutbillList, {
          params: {
            key: this.valus,
          },
        })
        .then((res) => {
          console.log(res, "aaa供应商");
          this.inoubill = res.data.data;
          console.log(this.inoubill,"aaaaa")
          this.restaurants = this.loadAll();
        });
    },
     // 可选择可搜索
     querySearch(queryString, cb) {
      console.log(queryString, "aaaaa");
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      console.log(results, "回调函数");
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        let item = restaurant.供应商名称 + "";
        return item.indexOf(queryString) === 0;
      };
    },

    loadAll() {
      return this.inoubill;
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    handleSelect(item) {
      console.log(item, "选择");

      this.stateList = item.供应商名称;
      // localStorage.setItem("nameB", this.state);
      localStorage.setItem("refName", item.码上放心供应商名称);
      localStorage.setItem("refCode", item.供应商代码);
      console.log(this.stateList, "a");
    },
    // 入库差异查询
    queryDiff(){
    console.log(this.stateList,"供应商代码")
    console.log(this.goodsVal,"货号")
    console.log(this.query.startTime,"开始时间")
    console.log(this.query.endTime,"结束时间")
    this.allSub.itemNo=this.goodsVal
    this.allSub.refUserId=this.stateList;
    this.allSub.beginTime=this.query.startTime
    this.allSub.endTime=this.query.endTime
    console.log(this.allSub,"最后")
    this.$axios
        .post(this.$api.billDifference, this.allSub)
        .then((res) => {
           if (res.data.code == 0) {
            console.log(res, "a");
          this.diffList=res.data.data
           var s=0
           var m=0
          
          this.diffList.forEach(element => {
               s+=Number(element.bookQuantity)
               this.yuren = s
               m+=Number(element.actualQuantity)
               this.shiji = m
          })
            
          } else {
            this.$toast({
              message: res.data.msg,
              duration: 3000,
            });
            return false;
          }
         
         
          // if (res.data.code == 0) {
          //   this.$toast({
          //     message: "保存成功",
          //     type: "success",
          //     duration: 3000,
          //   });
            
          // } else {
          //   this.$toast({
          //     message: res.data.msg,
          //     duration: 3000,
          //   });
          //   return false;
          // }
       
        });
    },
    cancelBtn(){
      this.choose_popup = false;
    },
    chooseDateBtn(){
      this.choose_popup = false;
      let month=this.currentDate.getMonth() + 1
      let newMonth=month >=10 ? month : "0" + month
      let day=this.currentDate.getDate()
      let newDay=day >=10 ? day : "0" + day
      let tie=`${this.currentDate.getFullYear()}-${newMonth}-${newDay}`;
      console.log(tie)
      console.log(this.currentDate,"时间")

      // let choose_time = this.getYYYYMMDD(this.currentDate)
      if(this.choose_type==1){//选中开始时间
        this.query.startTime = tie;
        console.log(this.query.startTime,"开始时间")
        
      }else{
        this.query.endTime = tie;
        console.log(this.query.endTime,"结束时间")
      }
      this.currentDate = new Date();
    },
    chooseDate(e){
        this.choose_type = e;
        this.choose_popup = true;
        this.popup_type = 1;
    },
    back() {
      this.$router.go(-1);
    //   this.$router.push('/point');
    },
  }
}

</script>
<style  scoped>
.every_option{
  height:7vh;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-bottom: 1px solid #E2E2E2;
}
.every_option1{
  height:7vh;
  display:flex;
  justify-content:space-between;
  align-items:center;

}
.every_select{
  display:flex;
  font-size:3.5vw;
  flex-wrap: nowrap;
 
}
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight:600;
}
</style>